import { graphql } from "gatsby"
import React, { useState } from "react"
import { SEO } from "shared/components"
import styled from "styled-components"
import { Layout } from "../components"

export const query = graphql`
  query {
    logo: file( relativePath: { eq: "logo.png" } ) {
      ...fluidImage
    }
  }
`

const Title1 = styled.h1`
  color: #f15f4a;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
`

const Title2 = styled.h2`
  color: #f15f4a;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ListItem = styled.li`
  background: #f15f4a;
  border-radius: 4px;
  color: white;
  margin: 20px;

  :hover {
    background: #bf4b3c;
  }
`

const LoginButton = styled.button`
  background: #f15f4a;
  border: 2px solid #f15f4a !important;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 45px;

  :hover {
    background: #f17664;
  }
`

const ListLink = styled.a`
  display: inline-block;
  padding: 10px;
  width: 100%;
`

const ListButton = styled.button`
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  width: 100%;
`

const StyledLink = styled.a`
  background: #f15f4a;
  border: 2px solid #f15f4a;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  padding: 5px 45px;

  :hover {
    background: #f17664;
  }
`

const Section = styled.div`
  margin-top: 50px;
`

const Form = styled.form`
  align-items: center;
  border: 2px #f15f4a solid;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: 350px;

  input {
    border: 2px solid grey;
    padding: 3px 0 3px 8px;
  }
`

const Submit = styled.input`
  background: #f15f4a;
  border: 2px solid #f15f4a !important;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: bold;

  :hover {
    background: #f17664;
  }
`

const Popup = styled.div`
  align-items: center;
  background: rgba( 0, 0, 0, 0.3 );
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99999;
`

const PopupContent = styled.div`
  align-items: center;
  background: white;
  border: 2px solid #f15f4a;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 80px 50px;
  position: relative;
`

const CloseButton = styled.p`
  color: #f15f4a;
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  right: 5px;
  top: -5px;

  :hover {
    color: #f17664;
  }
`

const HomePage = ( ) => {

  const [ loggedIn, setLoggedIn ] = useState( false )
  const [ username, setUsername ] = useState( "" )
  const [ password, setPassword ] = useState( "" )
  const [ wrongLogin, setWrongLogin ] = useState( false )
  const [ showPopup, setShowPopup ] = useState( false )

  const handleUser = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setUsername( e.target.value )
  }

  const handlePassword = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setPassword( e.target.value )
  }

  const showContent = ( ) => {
    setLoggedIn( true )
    setUsername( "" )
    setPassword( "" )
  }

  const login = ( e: React.MouseEvent ) => {
    e.preventDefault()

    username === "OneLocal" && password === "onelocal1122" ? showContent() : setWrongLogin( true )
  }

  const tryAgain = ( e: React.MouseEvent ) => {
    e.preventDefault()

    setWrongLogin( false )
  }

  const togglePopup = ( ) => {
      setShowPopup( !showPopup )
  }

  return (
    <Layout>
    <SEO title="LocalAds Layouts" description="Layout Picker for LocalAds" />
    <Section>
      { loggedIn ? (
        <>
          <Title2>Walkthrough</Title2>
          <div className="w-full mb-10">
            <List>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListButton onClick={ togglePopup }>Live Demo Account</ListButton> </ListItem>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="https://share.vidyard.com/watch/GvqpyqVQewfwQAcpx4RfKa?">Customer Submission View</ListLink> </ListItem>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="https://share.vidyard.com/watch/vXd8oK6yDFMo4g8TEpDzSX?">Client Submission View</ListLink> </ListItem>
            </List>
          </div>
          <Title1>LocalAds Layouts</Title1>
          <div className="w-full">
            <List>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="/auto-repair">Auto Repair</ListLink> </ListItem>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="/dental">Dental</ListLink> </ListItem>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="/hvac">HVAC</ListLink> </ListItem>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="/med-spa">Med Spa</ListLink> </ListItem>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="/movers">Movers</ListLink> </ListItem>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="/physio">Physiotherapy</ListLink> </ListItem>
              <ListItem className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" > <ListLink href="/plumbing">Plumbing</ListLink> </ListItem>
            </List>
          </div>
          { showPopup ? (
          <Popup>
            <PopupContent>
              <CloseButton onClick={ togglePopup }>X</CloseButton>
              <p className="mb-10">LocalContacts + LocalMessages are integrated to Krisp Klean (Demo) account. Submissions will go to <a href="mailto:connect@onelocal.com">connect@onelocal.com</a>.</p>
              <StyledLink href="https://krispklean.booknow.support">Continue</StyledLink>
            </PopupContent>
          </Popup>
          ) : null }
        </>
      ) : ( wrongLogin ? (
        <div className="w-full flex flex-col align-center">
          <p className="text-center mb-5">Wrong username or password. Please try again</p>
          <LoginButton onClick={ tryAgain } className="self-center">Login</LoginButton>
        </div>
      ) : (
        <div className="w-full flex justify-center">
          <Form>
            <Title2 className="mb-4">Login</Title2>
            <label htmlFor="username">Username</label>
            <input type="text" id="username" onChange={ handleUser } className="mb-3"/>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" onChange={ handlePassword } className="mb-10" />
            <Submit type="submit" onClick={ login } className="py-2 px-4" />
          </Form>
        </div>
       ) ) }
    </Section>
  </Layout>
  )
}

export default HomePage
